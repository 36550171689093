html {
    box-sizing: border-box;
    font-size: 62.5%;
    height: 100%;
}

*,
::before,
::after {
    box-sizing: inherit;
}

body {
    background-color: #f2f8ff;
    color: #0C3644;
    font-family: "Open Sans";
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    min-height: 100%;
  }
  
  body * {
      margin-bottom: 0;
  }

h1 {
    font-family: "Roboto";
    font-size: 2.304rem;
    font-weight: 500;
    margin: 1.6rem 0 0 0;
}

h1 + h2 {
    margin: 0.9888rem 0 0 0;
}

h1 + p {
    margin: 0.9888rem 0 0 0;
}

a {
  color: #c74638;
  cursor: pointer;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.flex-grow {
    flex-grow: 1;
}

input,
select {
    background-color: #fff;
    border: none;
    border-radius: 2px;
    color: #0C3644;
    font-family: "Open sans";
    font-size: 1.6rem;
    font-style: italic;
    font-weight: 300;
    margin: 0.3776rem 0 0 0;
    padding: 0.6110rem;
}

select {
    appearance: none;
        -webkit-appearance: none;
    cursor: pointer;
    background-image: url("drop-down-arrow.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
}

select::-ms-expand {
    display: none;
}
